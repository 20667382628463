body {
  font-family: Arial, sans-serif;
  background-color: #f6f7f9;
  color: #4a4a4a;
  padding: 20px;
}

.app {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #ff9908;
  text-align: center;
  font-weight: normal;
  margin-bottom: 20px;
}

h3 {
  color: #ff9908;
}

.new-post-form {
  display: flex;
  flex-direction: column;

  button {
    padding: 15px;
    background-color: #ff9908;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    cursor: pointer;

    &:hover {
      background-color: #e68a00;
    }
  }
}

.post-list {
  margin-top: 20px;
  border-top: 1px solid lightgray;
}

.post {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #ddd;
  margin: 10px 0;
  padding: 5px 5px 15px 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  .post-content {
    flex: 1;
    margin-right: 20px;
    white-space: pre-wrap;
  }

  .post-container {
    margin-right: -5em;
  }

  .post-right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 5em;

    .post-date {
      position: relative;
      bottom: 0;
      right: 0;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .votes {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -1em;

        button {
          padding: 10px;
          margin-bottom: 5px;
          background-color: transparent;
          color: #ff9908;
          border: none;
          cursor: pointer;
          font-size: 1.5em;

          &:hover {
            color: #e68a00;
          }
        }

        span {
          font-size: 1.2em;
          margin-bottom: 5px;
        }
      }
    }
  }

  .comment-form {
    display: flex;
    margin-top: 10px;

    input[type="text"] {
      padding: 10px;
      flex: 1;
      border: 1px solid #ddd;
      border-radius: 10px;
      margin-right: 10px;
      font-size: 1em;
    }

    button {
      padding: 10px;
      background-color: #ff9908;
      color: white;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      font-size: 1em;

      &:hover {
        background-color: #e68a00;
      }
    }
  }

  .comments {
    margin-top: 10px;

    .comment {
      padding: 10px;
      border: 1px solid #eee;
      border-radius: 10px;
      background-color: #fafafa;
      margin-bottom: 10px;

      .votes {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5px;

        button {
          padding: 10px;
          margin-bottom: 5px;
          background-color: transparent;
          color: #ff9908;
          border: none;
          cursor: pointer;
          font-size: 1.5em;

          &:hover {
            color: #e68a00;
          }
        }

        span {
          font-size: 1em;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.single-post-view {
  .close-post {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .close-button {
    font-size: 1.5em;
    cursor: pointer;

    &:hover {
      color: #ff9908;
    }
  }
}

textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1em;
  font-family: Arial, sans-serif;

  &.single-row {
    white-space: nowrap;
  }
}

.textarea-container {
  position: relative;
  width: 100%;

  .char-count-container {
    position: absolute;
    bottom: 8px;
    right: 12px;
    font-size: 12px;
    color: grey;
    background: white;
    padding: 2px 4px;
    border-radius: 4px;
  }

  .max-char-count {
    background-color: rgba(255, 0, 0, 0.25);
  }
}

button {
  padding: 15px;
  background-color: #ff9908;
  color: white;
  border: none;
  width: 100%;
  border-radius: 10px;
  font-size: 1em;
  cursor: pointer;

  &:hover {
    background-color: #e68a00;
  }
}

.comment {
  padding-left: 30px;
}

.post-container {
  width: 100%;
}

.nrOfComments {
  position: relative;
  right: -3em;
  height: 100%;
  display: flex;
  align-self: flex-end;
  align-items: center;
  flex-direction: row;
  font-size: 1.5em;
  padding: 0.35em;
  * {
    padding-right: 0.3em;
  }
  span {
    padding-right: 0.6em;
  }
  button {
    padding: 0.3em 0.8em;
    line-height: 2em;
    font-size: 0.8em;
  }
}

.icon {
  color: #ff9908;
}

textarea {
  resize: none;
}

.nav-bar {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.nav-button {
  height: 3em;
  width: 3em;
}

.nav-space {
  margin-left: auto;
}

.logout {
  margin-left: 0.5em;
  width: 25%
}

.spacer {
  height: 4em;
}